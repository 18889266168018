<template>
  <div class="supplier">
    <van-list v-model="loading" :finished="finished" :finished-text="$t.noMoreData" @load="onLoad">
      <div class="list">
        <supplier-list :listSupplier="listSupplier"></supplier-list>
      </div>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";

import { List } from "vant";
Vue.use(List);

import SupplierList from "./components/SupplierList";

export default {
  name: "Supplier",
  components: {
    SupplierList
  },
  data() {
    return {
      listSupplier: [], //产品
      type: 1,
      pageIndex: 1, //页码,
      loading: true,
      finished: false
      //isNoData: false,
      //isfirst: true
    };
  },
  mounted() {
    //首次加载数据
    this.getShopProductsByPage();
  },
  methods: {
    // 首次加载数据
    getShopProductsByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      // let param = {
      //   PageIndex: 1,
      //   ScreenValues: this.type
      // };
      let param = {};
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: 1
        };
      } else {
        param = {
          PageIndex: 1,
          ScreenValues: this.type
        };
      }
      this.$api.follow
        .loadFollowCustomerByPage(param)
        .then(res => {
          const data = res.data;
          this.listSupplier = data;
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          //this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    },
    //加载更多
    onLoad() {
      let param = {};
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: this.pageIndex + 1
        };
      } else {
        param = {
          PageIndex: this.pageIndex + 1,
          ScreenValues: this.type
        };
      }
      this.$api.follow
        .loadFollowCustomerByPage(param)
        .then(res => {
          const data = res.data;
          if (data === "" || data === null || data.length === 0) {
            this.loading = false;
            this.finished = true;
            //this.isNoData = true;
          } else {
            this.listSupplier = this.listSupplier.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
          }
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.supplier {
  width: 100%;
  font-size: 26px;
  .list {
    width: 100%;
  }
  .tabList {
    width: 100%;
    padding-top: 38px;
    text-align: center;
    background-color: #ffffff;
    .tabItem {
      width: 33.33%;
      text-align: center;
      font-size: 26px;
      color: #333333;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTop {
        @include puiblicFlex;
        text-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        .itemTopName {
          margin: 0;
          padding: 0;
        }
        .itemTopNum {
          margin: 0;
          padding: 0;
        }
      }
      .itemTopAction {
        color: #43cd9a;
      }
      .itemBorder {
        height: 8px;
        width: 60px;
        // background-color: #43cd9a;
        margin: 0 auto;
        border-radius: 16px;
        margin-top: 20px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #43cd9a;
        opacity: 1;
      }
    }
  }
}
</style>
